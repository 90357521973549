<template>
    <v-row class="contain-principles pa-5">
        <v-img v-if="!this.$vuetify.display.mobile" class="image-bg" src="@/assets/logos/logo-rsc-white.svg" cover />
        <v-col cols="12" md="4">
            <p class="text-h5 font-weight-bold">Visión</p>
            <p class="text-justify">Ser líderes en el desarrollo de aplicaciones que ayuden a las empresas a mejorar sus
                procesos y resolver
                problemas tecnológicos, innovar con las últimas tecnologías y metodologías para asegurar que nuestras
                aplicaciones sean de alta calidad y fáciles de usar. Queremos ser reconocidos por nuestra capacidad para
                entender las necesidades de nuestros clientes y ofrecer soluciones eficientes y escalables.</p>
        </v-col>
        <v-col cols="12" md="4">
            <p class="text-h5 font-weight-bold">Misión</p>
            <p class="text-justify">Ayudar a las empresas a alcanzar su máximo potencial mediante el desarrollo de
                aplicaciones que ayuden a mejorar sus procesos y resolver problemas tecnológicos. Nos esforzamos por
                entender las necesidades de nuestros clientes y ofrecer soluciones eficientes y escalables utilizando el más
                alto nivel de estándares en productividad, calidad, eficiencia, rentabilidad e Innovación tecnológica.</p>
        </v-col>
        <v-col cols="12" md="4">
            <p class="text-h5 font-weight-bold">Valores</p>
            <ul class="text-justify">
                <li>
                    <strong>Innovación:</strong> Nos esforzamos por estar a la vanguardia de la tecnología y fomentamos un
                    ambiente de creatividad y exploración.
                </li>
                <li>
                    <strong>Calidad:</strong> Nos comprometemos a ofrecer productos y servicios de la más alta calidad para
                    satisfacer las necesidades de nuestros clientes.
                </li>
                <li>
                    <strong>Transparencia:</strong> Creemos en la honestidad y la apertura en todas nuestras interacciones y
                    comunicaciones.
                </li>
                <li>
                    <strong>Trabajo en equipo:</strong> Valoramos la colaboración y el trabajo en equipo para lograr
                    nuestros objetivos comunes.
                </li>
                <li>
                    <strong>Aprendizaje continuo:</strong> Fomentamos un ambiente de aprendizaje constante para mantenernos
                    al día con las últimas tendencias y tecnologías.
                </li>
                <li>
                    <strong>Ética:</strong> Nos regimos por un conjunto de principios éticos que guían nuestras decisiones y
                    acciones.
                </li>
                <li>
                    <strong>Confidencialidad:</strong> Nos comprometemos a proteger la información privada o sensible de
                    nuestros clientes y socios comerciales.
                </li>
            </ul>

        </v-col>
    </v-row>
</template>
<script></script>
<style scoped>
.contain-principles {
    position: relative;
    background-color: #012a4c;
}

.image-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: 20px;
    margin-top: 20px;
    width: 350px;
    height: 350px;
}

p {
    color: #eee4e4 !important;
}
/* ul > li{
    color: #ffffff !important;
}
strong{
    color: #c9c6c6 !important;
} */
ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    li {
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
      color: #eee4e4 ;
    }
    li:last-child {
      border-bottom: none;
    }
    li strong {
      color: #1da5aa ;
    }
</style>