<template>
  <v-carousel v-if="this.$vuetify.display.mobile" cycle height="auto" hide-delimiter-background show-arrows="hover">
    <v-carousel-item src="https://servrsc.com/img/carousel/11.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/7.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/3.jpg" cover></v-carousel-item>
    <v-carousel-item src="https://servrsc.com/img/carousel/2.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/6.jpg" cover></v-carousel-item>
  </v-carousel>
  <v-carousel v-else cycle :height="wHeight" hide-delimiter-background show-arrows="hover">
    <v-carousel-item src="https://servrsc.com/img/carousel/11.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/7.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/3.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/2.jpg" cover></v-carousel-item>

    <v-carousel-item src="https://servrsc.com/img/carousel/6.jpg" cover></v-carousel-item>
  </v-carousel>
</template>
  
<script>

export default {
  name: 'Carousel',

  data: () => ({

  }),
  computed: {
    wHeight() {
      return window.innerHeight - 100
    }
  }
}
</script>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: linear-gradient(135deg, rgba(244, 244, 246, .8) 60%, rgba(19, 18, 24, 0.95) 60%);
}
</style>
  