<template>
  <v-app class="app-main">
    <v-system-bar color="primary-light-1">
      <v-icon icon="mdi-wifi-strength-4" class="ms-2"></v-icon>

      <v-icon icon="mdi-signal-cellular-outline" class="ms-2"></v-icon>

      <v-icon icon="mdi-battery" class="ms-2"></v-icon>

      <span class="ms-2">08:30</span>
    </v-system-bar>
    <v-app-bar color="primary" prominent>
      <v-app-bar-nav-icon v-if="this.$vuetify.display.mobile" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title><v-img v-if="!this.$vuetify.display.mobile" src="@/assets/logos/logo-rsc-white.svg" max-width="85" contain></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <p v-if="this.$vuetify.display.mobile" class="text-left">Transformamos grandes ideas de negocio en soluciones de software</p>

      <v-btn v-if="!this.$vuetify.display.mobile" variant="text" class="text-button">Acerca de</v-btn>

      <v-btn v-if="!this.$vuetify.display.mobile" variant="text" class="text-button">Servicios</v-btn>

      <v-btn v-if="!this.$vuetify.display.mobile" variant="text" class="text-button">Contacto</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list>
        <v-list-item prepend-avatar="assets/logos/logo-rsc-white.png" title="Sandra Adams"
          subtitle="sandra_a88@gmailcom"></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-folder" title="My Files" value="myfiles"></v-list-item>
        <v-list-item prepend-icon="mdi-account-multiple" title="Shared with me" value="shared"></v-list-item>
        <v-list-item prepend-icon="mdi-star" title="Starred" value="starred"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: 'Foo',
        value: 'foo',
      },
      {
        title: 'Bar',
        value: 'bar',
      },
      {
        title: 'Fizz',
        value: 'fizz',
      },
      {
        title: 'Buzz',
        value: 'buzz',
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
  mounted() {
    console.log(this.$vuetify.display.mobile)
  }
}
</script>
<style lang="scss">
.app-main {
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.img-rsc {
  z-index: 1000;
}
</style>
