<template>
  <rsx-carousel />
  <v-container>

    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="require('../assets/logos/logo-rsc.svg')" contain height="200" />
      </v-col>

      <v-col cols="12" md="12" class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Business & Technologies
        </h1>


        <p class="subheading font-weight-regular">
          Aprovecha el poder de la tecnología y los datos en todos los sectores,
          <br>Transformamos grandes ideas de negocio en soluciones de software
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <h1 class="display-2 font-weight-bold mb-3">
          Datos de la empresa
        </h1>
        <p class="text-justify">Nuestro proposito es asegurarnos que está conduciendo al máximo su inversión en el
          software. </p>
        <div class="container">
          <div class="item">
            <div class="number">26</div>
            <div class="text-coumt">Completed Projects</div>
          </div>
          <div class="item">
            <div class="number">26</div>
            <div class="text-coumt">Satisfied Clients</div>
          </div>
          <div class="item">
            <div class="number">3</div>
            <div class="text-coumt">Winning Award</div>
          </div>
        </div>
        <p class="title-primary mt-10 text-left text-h6">¿Quiénes somos? </p>
        <p class="title-secondary text-left text-h4 font-weight-bold">
          SOBRE NOSOTROS</p>

        <p class="mt-5 text-justify text-body-2">Somos una empresa Mexicana, de desarrollo de software a la medida, que se asocia con clientes para ofrecer
          soluciones de software de alta calidad adaptadas a las necesidades del cliente. Trabajamos estrechamente con
          cada cliente para comprender sus objetivos, desafíos y requisitos.
        </p>

        <p class="mt-5 text-justify text-body-2">Con 8 años de experiencia en proyectos de la industria petrolera y sectores privados, nuestro equipo de expertos en desarrollo de aplicaciones trabajará con usted para entender sus necesidades y diseñar una solución a medida utilizando las últimas tecnologías y metodologías para asegurar que nuestras aplicaciones sean eficientes, escalables y fáciles de usar que ayuden a nuestros clientes a alcanzar sus objetivos comerciales.
        </p>

      </v-col>

      <v-col cols="12" md="6">
        <div class="div-overflow">
          <img src="https://servrsc.com/img/carousel/2.jpg" class="imagen-circular">
        </div>
      </v-col>
    </v-row>
  </v-container>
  <StatamentsPrinciples/>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import { RsxCarousel, StatamentsPrinciples } from '../components';

export default defineComponent({
  name: 'HomeView',

  components: {
    RsxCarousel, StatamentsPrinciples
  },
});
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f0f0f0;
}

.item {
  text-align: center;
  border-right: 1px dotted #888;
  padding: 20px;
}

.item:last-child {
  border-right: none;
}

.number {
  font-size: 2em;
  color: #800080;
}

.text-coumt {
  color: #800080;
}

.imagen-circular {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 50%;
  width: 700px;
  height: 700px;
  outline: 8px solid #012a4c;
  border: 5px solid #ffffff;
}

.div-overflow {
  overflow: hidden;
  position: relative;
  width: 600px;
  height: 600px;
}

.title-primary {
  color: #1da5aa !important;
}

.title-secondary {
  color: #0b174d !important;
}</style>