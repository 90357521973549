// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'rscTheme',
    themes: {
      rscTheme: {
        dark: false,
        variables: {},
        colors: {
          background: '#fbfbfb',
          surface: '#212121',
          primary: '#012a4c',
          'primary-light-1': '#0E619F',
          'primary-light-2': '#023859',
          secondary: '#eff2eb',
          'secondary-darken-1': '#03DAC5',
          'dark-rsc': '#0D0D0D',
      
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        }
      }
    }
  }
})
